import React from "react";
import { graphql } from "gatsby";

import Index from "../../templates/IndexTemplate";

const needles = {
	muovailu: "dough",
	hiljainen: "quiet",
	yhteistyo: "collaboration",
	rohkaisu: "database",
	historia: "history",
	touko: "touko",
};

const IndexPage = ({
	data: {
		allPages: { edges },
		page,
		mecpage,
		contactpage,
		clickpage
	},
}) => {
	const edgesData = edges.filter(
		(item) => item.node.locale.locale === "en_US"
	);
	return (
		<Index
			page={page}
			edges={edgesData}
			needles={needles}
			mecInfoPage={mecpage}
			contactPage={contactpage}
			clickPage={clickpage}
			locale="en"
		/>
	);
};

export default IndexPage;

export const query = graphql`
	query {
		allPages: allWpPage {
			edges {
				node {
					title
					slug
					locale {
						locale
					}
					frontpageLinkTitle {
						styledTitle
					}
				}
			}
		}
		page: wpPage(slug: { eq: "welcome-to-mec" }) {
			content
			title
			seo {
				metaDesc
				title
			}
		}

		mecpage: wpPage(slug: { eq: "what-mec-really-means" }) {
			slug
			title
		}

		contactpage: wpPage(slug: { eq: "contact-form" }) {
			slug
			title
		}

		clickpage: wpPage(slug: { eq: "click" }) {
			slug
			title
		}
	}
`;
